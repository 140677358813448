body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F5FB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.shadowBox {
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.lineBreak {
  white-space: pre-line;
}

@font-face {
  font-family: AvenirNextCondensedBoldSkew;
  src: url(./fonts/AvenirNextCondensed-Bold-5-Degree.ttf);
}

@font-face {
  font-family: AvenirNextCondensedDemiBoldSkew;
  src: url(./fonts/AvenirNextCondensed-DemiBold-5-Degree.ttf);
}

@font-face {
  font-family: AvenirNextMedium;
  src: url(./fonts/AvenirNextLTPro-Medium.otf);
}

@font-face {
  font-family: AvenirNextDemiBold;
  src: url(./fonts/AvenirNextLTPro-Demi.otf);
}

@font-face {
  font-family: AvenirNextCondensedBoldItalic;
  src: url(./fonts/AvenirNextCondensed-BoldItalic.ttf);
}

@font-face {
  font-family: AvenirNextCondensedMediumItalic;
  src: url(./fonts/AvenirNextLTPro-It.otf);
}

@font-face {
  font-family: AvenirNextRegular;
  src: url(./fonts/AvenirNextLTPro-Regular.otf);
}

@font-face {
  font-family: PelikinSolid3;
  src: url(./fonts/pelikin-solid-part-3.ttf);
}

@font-face {
  font-family: PelikinSolidIcon;
  src: url(./fonts/pelikin-solid.ttf);
}

@font-face {
  font-family: Icomoon;
  src: url(./fonts/icomoon.ttf);
}

ul {
  color: white;
}

.flex-wrap {
  word-break: break-all;
}

.button {
  outline: none;
  cursor: pointer;
}

.item-hover-underline {
  color: #fa2e81;
  cursor: pointer;
}

.item-hover {
  color: #1a1f71;
  outline: none;
  cursor: pointer;
}

.item-hover:hover {
  color: #fa2e81;
  outline: none;
  cursor: pointer;
}

.box-hover:hover {
  .product-hover {
    background-color: #fa2e81;
  }
  cursor: pointer;
}

.share-hover:hover {
  color: #ccff02;
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.67);
}

.gradientOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(26, 31, 113, 0.9), rgba(250, 46, 129, 0.9));
}

/* width */
.modalScroll::-webkit-scrollbar {
  width: 5px;
  padding: 100px;
}

/* Track */
.modalScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
.modalScroll::-webkit-scrollbar-thumb {
  background: #1a1f71;
  border-radius: 10px;
}

.item-image {
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.6s;
}
.item-image:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.slider {
  height: 110px;
  overflow: hidden;
  padding: 0 10px;
}

.slider > div {
  box-sizing: border-box;
  height: 110px;
  margin-bottom: 50px;
  padding: 0 10px;
  text-align: center;
}

.bookingModal {
  overflow: hidden;
  padding: 20px;
}

.gradientModal {
  overflow: hidden;
  padding: 0px;
}

.pelikin-logo {
  animation: curtain 2s linear infinite;
}

.clearfix {
  overflow: auto;
}

@keyframes curtain {
  0% {
    clip-path: inset(100% 0 0 0);
  }
  50% {
    clip-path: inset(0);
  }
  100% {
    clip-path: inset(0 0 100% 0);
  }
}

/** Calendar Styling Overide */

.Calendar {
  background: #f3f5fb !important;
  border-radius: 0px !important;
  z-index: 0 !important;
  width:  83% !important;
  height: 30em !important;
  min-height: 30em !important;
  margin: 0 1rem !important;
  box-shadow: none !important;
}

.Calendar__day {
  font-family: AvenirNextMedium !important;
  color: #1a1f71 !important;
  letter-spacing: -0.5px;
}

.Calendar__weekDay {
  color: rgba(26, 31, 113, 0.7) !important;
}

.Calendar > :not(.Calendar__footer) button {
  font-family: AvenirNextDemiBold !important;
  color: #1a1f71 !important;
  letter-spacing: -0.5px;
}

.Calendar__monthArrow {
  background-image: url("./assets/travelExperience/chevron-up.png") !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: var(--cl-color-primary);
  color: #1a1f71 !important;
  border-radius: 12% !important;
}

.plus-minus {
  color: #1a1f71;
}

a:link {
  text-decoration: none;
}

@keyframes moving-bar {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(400px);
  }
}
.inputForm {
  border: none;
  background-color: "#F3F5FB";
  color: "#1A1F71";
  font-family: "AvenirNextDemiBold";
  outline: none;
  ::-webkit-input-placeholder {
    font-family: "AvenirNextRegular";
    color: "#1A1F71";
  }
}

.transitionFix {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* For Safari input autoFill box color  */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}